@mixin flex-column {
  display: flex;
  flex-direction: column;
}
@mixin flex-row {
  display: flex;
  flex-direction: row;
}
@mixin center-align-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin center-items-justify {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

@mixin center-items {
  display: flex;
  align-items: center;
  justify-content: center;
}

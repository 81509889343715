// Global styles
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  font-family: $font-family;
  font-size: 18px;
  @media screen and (max-width: $breakpoint-mobile) {
    font-size: 12px;
  }
}
main {
  height: auto;
  flex: 1 0 auto;
}

#house{
  margin: 0 5% 0;
}
footer {
  margin-top: auto;
}



* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
}
@media screen and (max-width: 800px) {
  body {
    font-size: 12px;
  }
}

main {
  height: auto;
  flex: 1 0 auto;
}

#house {
  margin: 0 5% 0;
}

footer {
  margin-top: auto;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
@keyframes rotation-back {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes apparition {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes disparition {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2% 5% 2%;
  padding: 0;
}
.navbar_logo {
  max-width: 10vw;
  height: auto;
}
@media screen and (max-width: 800px) {
  .navbar_logo {
    margin: 3% 0 2%;
    max-width: 20vw;
  }
}
.navbar_logo-image {
  width: 100%;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
}
.navbar_links {
  min-width: 20%;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 800px) {
  .navbar_links {
    min-width: 25%;
    text-transform: uppercase;
  }
}
.navbar_links ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  list-style: none;
}
.navbar_links ul li {
  font-size: 1.2em;
  font-family: "Montserrat", sans-serif;
  justify-content: space-between;
}
.navbar_links ul li:not(:last-child) {
  margin-right: 1rem;
}
.navbar_links-link {
  text-decoration: none;
  color: #000000;
}
.navbar_links-link:focus {
  border-bottom: 1px solid #000000;
}
.navbar_links-link:last-child {
  margin-right: 0;
}

@media (min-width: 768px) {
  .navbar_links ul li {
    display: flex;
    justify-content: space-between;
  }
}
.banner_section {
  width: auto;
  height: 225px;
  margin: 0 5% 2%;
  border-radius: 10px;
  background-color: #000000;
  position: relative;
}
@media screen and (max-width: 800px) {
  .banner_section {
    height: 111px;
  }
}
.banner_picture {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  filter: brightness(50%);
}
.banner_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 2em;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 800px) {
  .banner_text {
    font-size: 24px;
  }
}

.container {
  width: auto;
  margin: 0 5% 5%;
  padding: 3%;
  background-color: #f6f6f6;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: normal;
}
@media screen and (max-width: 800px) {
  .container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.container .card {
  width: 340px;
  height: 300px;
  padding: 0;
  margin: 0 0 5%;
  border-radius: 10px;
  color: #ffffff;
  position: relative;
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}
@media screen and (max-width: 800px) {
  .container .card {
    width: 85vw;
    height: 200px;
  }
}
.container .card_image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}
.container .card_title {
  max-width: 50%;
  font-size: 1.1em;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  position: absolute;
  bottom: 10px;
  margin: 5% 5% 0;
}

.modal {
  width: 85%;
  height: auto;
  margin: 0 auto 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
@media screen and (max-width: 800px) {
  .modal {
    width: 90%;
  }
}
.modal__container {
  margin: 3% 0 3%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
}
@media screen and (max-width: 800px) {
  .modal__container {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-width: 100%;
    min-height: 15vh;
  }
}
.modal--housePage {
  width: 100%;
  color: #000000;
}
.modal--housePage > p {
  font-size: 1.5em;
  line-height: 1.5;
  font-weight: 400;
  color: #000000;
}
.modal--housePage:first-child {
  margin-right: 2%;
}
.modal--housePage:last-child {
  margin-left: 2%;
}
@media screen and (max-width: 800px) {
  .modal--housePage {
    min-width: 100%;
  }
  .modal--housePage:first-child {
    margin: 0 0 2%;
  }
  .modal--housePage:last-child {
    margin: 0;
  }
}
.modal_title {
  z-index: 2;
  width: 100%;
  height: calc(50px + 1vh);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #FF6060;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
}
@media screen and (max-width: 800px) {
  .modal_title {
    width: 100%;
    height: calc(30px + 2vh);
  }
}
.modal_title--name {
  padding: 0 3% 0;
  color: #ffffff;
  font-size: 1.1em;
  font-weight: 500;
}
@media screen and (max-width: 800px) {
  .modal_title--name {
    font-size: 1.4em;
  }
}
.modal_title--icon {
  padding: 0 3% 0;
  color: #ffffff;
  font-size: 24px;
  cursor: pointer;
}
.modal_title--icon.rotate-up {
  transform: rotate(0deg);
  transition: transform 0.35s ease-in;
}
.modal_title--icon.rotate-down {
  transform: rotate(180deg);
  transition: transform 0.35s ease-in;
}
.modal_text {
  padding: 1rem;
  opacity: 0;
  transition: transform 0.35s linear;
}
.modal_text.open {
  z-index: 1;
  opacity: 1;
  animation: apparition 0.35s ease-in-out forwards;
}
.modal_text.close {
  opacity: 1;
  animation: disparition 0.35s ease-in-out forwards;
}

.error_container {
  width: 100%;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  align-content: space-between;
  font-family: "Montserrat", sans-serif;
}
.error_container p:nth-child(1) {
  margin: 0;
  font-size: 15em;
  font-weight: 700;
  color: #FF6060;
}
.error_container p:nth-child(2) {
  margin: 3% 0 7%;
  font-size: 1.8em;
  font-weight: 500;
  color: #FF6060;
}
.error_container .error-navlink {
  margin-bottom: 5%;
  color: #000000;
  font-size: 1.1em;
  font-weight: 500;
  text-decoration: underline;
}
@media screen and (max-width: 800px) {
  .error_container p:nth-child(1) {
    font-size: 6em;
    text-align: center;
  }
  .error_container p:nth-child(2) {
    width: 70%;
    font-size: 2em;
    text-align: center;
  }
}

.house {
  display: flex;
  flex-direction: column;
}
.house__content {
  height: auto;
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media screen and (max-width: 800px) {
  .house__content {
    flex-direction: column;
  }
}
.house_rating {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.5em;
}
.house_rating-on {
  color: #FF6060;
}
.house_rating-off {
  color: #e3e3e3;
}
@media screen and (max-width: 800px) {
  .house_rating {
    max-width: 35%;
    min-width: 0;
    font-size: 1.6em;
  }
}
.house_location {
  color: #000000;
  font-weight: 500;
  font-size: 18px;
}
.house_title {
  font-size: 1.7em;
  font-weight: 500;
  margin: 2% 0;
  color: #FF6060;
}

.house__tags {
  margin: 3% 0 0;
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  flex-wrap: wrap;
}

.house__tags_container--tag {
  display: flex;
  min-height: 30px;
  margin-right: 10px;
  margin: 10px 10px 10px 0;
  padding: 0 30px 0;
  background-color: #FF6060;
  color: #e3e3e3;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
}

.host {
  display: flex;
}
@media screen and (max-width: 800px) {
  .host {
    margin-bottom: 5%;
  }
}
.host__maincontainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
}
.host__maincontainer--picture {
  border-radius: 50%;
  width: 8vw;
}
@media screen and (max-width: 800px) {
  .host__maincontainer {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
  }
}
.host__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.3em;
}
@media screen and (max-width: 800px) {
  .host__container {
    margin-bottom: 0;
  }
}
.host__container--picture {
  border-radius: 50%;
  width: 64px;
}
@media screen and (max-width: 800px) {
  .host__container--picture {
    width: 32px;
  }
}
@media screen and (max-width: 800px) {
  .host__container {
    flex-direction: row;
  }
}
.host__namecontainer {
  display: flex;
  flex-direction: column;
}
.host--name {
  color: #FF6060;
  margin-right: 10px;
  font-size: 1em;
  white-space: pre-wrap;
}
@media screen and (max-width: 800px) {
  .host--name {
    font-size: 1.3em;
    font-weight: 500;
  }
}

.description {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2% 0;
}
.description__container_title {
  border-radius: 10px;
  font-size: 1.2em;
  font-weight: 500;
  background-color: #FF6060;
  width: 40%;
  min-height: 40px;
  margin: 1%;
  padding: 0 2% 0;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.description .house_modal {
  background-color: #FF6060;
  display: flex;
  flex-direction: column;
}

.slides-container {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slides-container--bloc {
  width: 100%;
  position: relative;
}
.slides-container__nbr {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 1.1em;
  font-weight: 700;
}
@media screen and (max-width: 800px) {
  .slides-container__nbr {
    display: none;
  }
}
.slides-container__img {
  width: 100%;
  height: 50vh;
  border-radius: 10px;
  -o-object-fit: cover;
     object-fit: cover;
}
.slides-container__arrow {
  position: absolute;
  color: #ffffff;
  font-size: 3em;
  cursor: pointer;
  z-index: 1;
}
.slides-container__arrow--left {
  left: 10px;
  margin: 0;
}
.slides-container__arrow--right {
  right: 10px;
  margin: 0;
}

.footer {
  flex-shrink: 0;
  width: 100%;
  min-height: 30vh;
  margin-top: 3em;
  background-color: #000000;
  color: #ffffff;
  font-size: 1.2em;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.footer .footer-logo {
  margin-bottom: 2%;
}
@media screen and (max-width: 800px) {
  .footer {
    height: 20vh;
    font-size: 12px;
  }
  .footer h3 {
    width: 40%;
    word-break: break-word;
    white-space: normal;
  }
}

.about {
  min-height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.about__content {
  margin-top: 1em;
  width: 100%;
  min-height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-content: space-evenly;
  gap: 20px;
}

#modal_title-about {
  height: calc(65px + 1vh);
}
@media screen and (max-width: 800px) {
  #modal_title-about {
    height: calc(40px + 1vh);
    font-size: 10px;
  }
}/*# sourceMappingURL=main.css.map */
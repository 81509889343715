$color-primary: #FF6060;
$color-background: #000000;
$color-text: #ffffff;
$radius: 10px;
$breakpoint-mobile: 800px;

// Text
$font-family: "Montserrat", sans-serif;
$font-size: 1.2em;
$font-weight: 500;
$margin: 2% 0;